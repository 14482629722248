import React from 'react'
import {Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout/layout'
import { Container, StyledButton, Subline } from '../components/layout/layoutComponents'
import { typo, colors } from '../components/utils'
import TeamMember from '../components/team/teamMember'
import BusinessesSliderEN from '../components/businesses/businessesSliderEN'

const Hero = styled.div`
  padding: 0 35px;
  margin: 0 auto;
  width: 100%;
  height: 465px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 724px) {
    flex-direction: row;
    align-items: bottom;
    height: 590px;
    justify-content: space-evenly;
  }
`

const HeroHeader = styled.h1`
  margin-top: 35px;
  font-size: 32px;
  line-height: 38px;
  color: ${colors.orange};
  letter-spacing: 6px;
  text-transform: uppercase;
  @media (min-width: 724px) {
    margin-left: 30px;
    line-height: 58px;
    max-width: 600px;
  }
  @media (min-width: 1024px) {
    font-size: 68px;
    line-height: 76px;
  }
`
const HeroImage = styled.img`
  max-height: 240px;
  width: auto;
  margin: 0;
  position: relative;
  @media (min-width: 720px) {
    max-height: 320px;
    bottom: -90px;
  }
  @media (min-width: 989px) {
    max-height: 550px;
  }
`
const StyledArticle = styled.article`
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 35px 70px;
  font-size: 20px;
  h2 {
    font-size: ${typo.fontheadline};
    font-weight: ${typo.bold};
    line-height: ${typo.lineheadline};
    color: ${colors.default};
    text-transform: uppercase;
    margin: 0;
  }
  h3 {
    font-size: ${typo.fontsubline};
    font-weight: ${typo.bold};
    line-height: ${typo.linesubline};
    color: ${colors.orange};
    text-transform: uppercase;
    margin: 0;
  }
`
const ArticleText = styled.div`
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  p {
    flex: 40%;
    max-width: 40%;
    font-size: 16px;
    font-weight: ${typo.regular};
    line-height: 22px;
    color: ${colors.default};
    margin: 0;
    margin-bottom: 30px;
  }
  ul {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    font-size: 16px;
    font-weight: ${typo.regular};
    line-height: 22px;
    color: ${colors.default};
    list-style: none;
    li {
      position: relative;
      margin-bottom: 15px;
      &:before{
        content: '';
        position: absolute;
        top: 8px;
        left: -39px;
        height: 13px;
        width: 13px;
        background-color: ${colors.orange};
      }
    }  
  }
`

const TeamSection = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 55px 0;
  display: flex;
  justify-content: space-between;
`
const MemberContainer  = styled.div`
  max-width: 400px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  img {
    max-width: 400px;
    width: auto;
  }
`

export default function BusinessTemplate({ pageContext: { data }})  {
  const content = data.article
  return (
    <Layout>
    <Hero>
         <HeroImage src={data.image[0].url} placeholder={data.title} />
        <HeroHeader>{data.title}</HeroHeader>
    </Hero>
    <Container>
      <TeamSection>
        <div style={{width: `100%`, margin: `50px 15px 30px`}}>
          <Subline style={{margin: `50px 15px`}}>{data.ctaText}</Subline>
      <MemberContainer>
        <TeamMember
          src={data.team[0].image[0].url}
          intro={data.team[0].teamIntro}
          alt={data.team[0].teamame}
          name={data.team[0].teamame}
          role={data.team[0].teamRole}
          mail={data.team[0].email}
          phone={data.team[0].phoneNumber}
        />
        <StyledButton style={{maxWidth: `130px`}}><Link to="/contact">Contact</Link></StyledButton>
      </MemberContainer>
        </div>
      </TeamSection>
      <StyledArticle>
        <Subline>{data.textHeader}</Subline>
        <ArticleText dangerouslySetInnerHTML={{ __html: content }} />
        
      </StyledArticle>
    </Container>
    <BusinessesSliderEN />
  </Layout>
  )
} 


