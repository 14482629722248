import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { Container, Subline } from '../layout/layoutComponents'
import { NextArrow, PrevArrow } from '../slider/slider'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const SlideInner = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 85%;
  justify-items: center;
  align-items: center;
  text-align: center;
`

const ItemLink = styled(Link)`
  text-decoration: none;
  color:#F28E00;
  cursor: pointer;
`

const SliderSection = styled.section`
  background-color: rgba(156, 158, 159, 0.2);
  padding-bottom: 60px;
  width: 100%;
  margin: 0 auto;
  justify-items: center;
  margin-top: 45px;
`
const SlidesContainer = styled.div`
  max-width: 1040px;
  margin: 0 auto;
`

const SliderContainer = styled.div`
  display: flex;
`
const SliderImage = styled.img`
  max-height: 195px;
`
const ItemTitle = styled.h6`
  color:#F28E00;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 26px;
`

const settings = {
  autoplay: false,
  infinite: true,
  arrows: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 3
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1
      }
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2
      }
    }
  ]
};


function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <NextArrow role="arrow"
      className={className}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <PrevArrow role="arrow"
      className={className}
      onClick={onClick}
    /> 
  );
}


const Businesses = () => (
  <StaticQuery
    query={graphql`
    query {
      craftHomepageHomepageEntry {
        businesses {
          ... on Craft_businesses_businesses_Entry {
            id
            image {
              url
            }
            slug
            title
          }
        }
      }
    }
  `}

    render={({craftHomepageHomepageEntry}) => (
        <SliderSection>
          <Subline style={{margin: `0 auto`, textAlign: `center`, padding: `40px 0`}}>MORE BRANCHES</Subline>
          <Container>
            <SlidesContainer>
              <Slider {...settings}>
                {craftHomepageHomepageEntry.businesses.map(item => (
                  <SliderContainer>
                  <ItemLink to={`/${item.slug}`}>
                    <SlideInner>
                      <SliderImage src={item.image[0].url} />
                      <ItemTitle>{item.title}</ItemTitle>
                    </SlideInner>
                  </ItemLink>
                  </SliderContainer>
                ))}
              </Slider>
            </SlidesContainer>
          </Container>
        </SliderSection>
    )}
  />
);

export default Businesses;